.customModal {
    @apply shadow-none;
    max-width: 1200px !important;
    background: none !important;
    padding: 2.2rem !important;
}

.customCloseButton {
    top: 0px !important;
    right: 0px !important;
    &:focus {
        border: 0;
        outline: 0;
    }
    &:active{
        border: 0;
        outline: 0;
    }
}

.customCloseButton svg {
    fill: #fff;
}

.screen03-warp {
    @apply pt-20 lg:pt-0 w-screen h-auto pb-24 md:pb-0 flex flex-col justify-start lg:justify-center items-center;
}
.screen03-warp .title{
    @apply w-full text-left px-5 pt-8 pb-10 text-5xl font-medium leading-14 lg:hidden;
}
.screen03-layout {
    @apply mx-auto w-full lg:space-x-8 flex box-border flex-col lg:flex-row lg:justify-between lg:items-stretch lg:w-960 xl:w-1200;
}
.screen03-layout-left {
    @apply hidden h-full lg:h-auto justify-between lg:flex lg:flex-1 flex-col text-white xl:pr-10 2xl:pr-14  xl:h-600 box-border xl:pt-28 relative;
}
.screen03-title {
    @apply text-left pl-3 xl:pl-0 lg:text-xl 2xl:text-2xl mt-3;
}
.screen03-introduce {
    @apply left-0 pl-3 xl:pl-0 opacity-70;
}
.screen03-introduce-p {
    @apply text-left text-base opacity-70 flex-1 leading-7 mt-5;
}
.screen03-introduce-button-warp {
    @apply lg:h-14 px-2 border-opacity-10 xl:px-4 flex justify-between items-center box-border border border-white backdrop-blur-xl lg:rounded-xl xl:rounded-3xl xl:h-20 lg:py-0.5;
}
.screen03-introduce-button {
    @apply w-12 h-12 xl:w-16 xl:h-16 p-1 flex justify-center items-center lg:rounded-xl xl:rounded-2xl hover:bg-white hover:bg-opacity-10;
}
.screen03-introduce-warp {
    @apply hidden w-full lg:block rounded-lg lg:w-96 lg:h-96 xl:w-1/2 2xl:h-600 relative;
}
.screen03-introduce-warp-video {
    @apply w-full h-full absolute inset-0;
}
.screen03-introduce-video {
    @apply h-full w-full object-cover rounded-2xl;
}
.screen03-show-model-tap {
    @apply absolute w-full flex justify-between inset-x-0 top-1/2 -mt-4 px-3;
}
.screen03-show-model-button-left {
    @apply w-8 h-8 flex justify-center items-center rounded-full bg-black opacity-10 hover:opacity-70;
}
.screen03-show-model-button-right {
    @apply w-8 h-8 flex justify-center items-center rounded-full bg-black opacity-10 hover:opacity-70;
}
.screen03-switch-warp {
    @apply lg:hidden;
}
.screen03-switch-button {
    @apply border-opacity-10 mx-0.5 px-5 min-w-min flex justify-between items-center sm:space-x-9 box-border border border-white backdrop-blur-xl rounded-3xl h-20 py-0.5;
}
.screen03-switch-button-list {
    @apply w-16 h-16 p-1 flex justify-center items-center rounded-2xl;
}
.screen03-video-warp {
    @apply box-border px-5 pt-5;
}
.screen03-video-warp-switch {
    @apply h-full box-border flex flex-col justify-between pt-6 border border-white border-opacity-5 rounded-3xl bg-white bg-opacity-5;
}
.screen03-video-warp-switch-div1 {
    @apply text-left md:text-center px-3 text-3xl leading-9;
}
.screen03-video-warp-switch-div2 {
    @apply text-base text-left leading-7 opacity-70 px-3;
}
.screen03-warp-switch-video-div {
    @apply mt-5;
}
.screen03-warp-switch-video {
    @apply h-full w-full object-cover rounded-3xl;
}

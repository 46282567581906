.sc-screen07-title {
    left: 0;
    right: 0;
    margin: auto;
}

.m-screen07-grid {
    display: grid;
    grid-template-columns: repeat(4, 0.2fr);
    grid-gap: 2px;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 30px;
}

.u-screen07-item div {
    background: rgba(131, 122, 153, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 20px;
}

.screen07-warp {
    @apply w-screen py-20 px-5 lg:px-8 mx-auto md:py-0 xl:w-1200 xl:px-0;
}
.screen07-warp-h1 {
    @apply text-left md:text-center mt-16 font-sans text-4xl font-bold md:mt-12;
}
.screen07-warp-img-div {
    @apply grid grid-cols-2 gap-5 lg:gap-7 pb-20 mt-8 xl:pb-0 md:grid-cols-3 lg:grid-cols-5;
}
.screen07-warp-img {
    @apply flex justify-center xl:w-56 xl:h-28 p-6;
}
/* .screen07anim { */
    /* min-height: calc(100vh + 340px); */
    /* @apply md:min-h-full */
/* } */
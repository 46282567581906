#circle {
    margin: 20px auto;
    width: 100px;
    height: 100px;
    background: #fff;
    color: #000;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
}

.full-video-bg {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}
.screen01 {
    @apply relative z-10 flex items-center justify-center h-full px-4 mx-auto overflow-hidden;
}
.screen01-warp {
    @apply overflow-hidden w-1200 py-28 md:py-0;
}
.screen01-warp-title {
    @apply mx-auto text-5xl lg:text-6xl font-bold text-white 2xl:w-auto;
}
.screen01-warp-summary {
    @apply pt-8 font-sans text-2xl text-white;
}
.screen01-warp-button {
    @apply flex flex-col items-center justify-center mx-auto mt-14 md:flex-row;
}

.screen01-warp-button-play {
    @apply py-3 mb-6 text-lg font-semibold text-center border-2 rounded-full w-60 text-yellow border-yellow md:mr-10 hover:bg-yellow-r hover:text-black;
}
.screen01-warp-button-whitepaper {
    @apply py-3 mb-6 text-lg font-semibold text-center text-black border-2 rounded-full w-60 border-yellow bg-yellow-r hover:bg-yellow-hover md:ml-10;
}

.wrapper {
    @apply fixed bottom-10 left-10 z-50 border border-white border-opacity-20 rounded-full p-2 bg-white bg-opacity-20;
}

.social-btn-wrap {
    @apply flex space-x-5;
}

.social-btn-wrap > a {
    @apply w-12 h-12 block text-white rounded-full bg-white bg-opacity-10 p-2 cursor-pointer;
    transition: all 300ms linear;
}


.social-btn-wrap > a:hover {
    @apply bg-opacity-30;
}

.expand-btn {
    @apply flex w-12 h-12 text-white p-4 box-border rounded-full ml-2;
}

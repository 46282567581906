body {
    font-family: 'Robot';
}
.custom-section {
    text-align: center;
    /* font-size: 4em; */
    color: #fff;
}

.actions {
    list-style-type: none;
}
.actions button {
    padding: 0.93em 1.87em;
    background: #35495e;
    border-radius: 5px;
    border: 0;
    color: #fff;
    margin: 7px;
    font-size: 15px;
    cursor: pointer;
}
.actions button:hover {
    background: #fff;
    color: #35495e;
}
.screen02 {
    margin-top: calc(20vh - 88px);
}
.screen02-video {
    height: 60vh;
}
.screen03 {
    padding-top: calc((100vh - 500px + 88px) / 2);
}
.screen02-img {
    width: 100%;
}
@media (min-width: 1024px) {
    .screen02-img {
        width: calc((100vw - 1200px) / 2 + 600px);
    }
}
.page-6 > .fp-tableCell {
    background: #000000 url('./assets/images/bg.png') no-repeat;
}

/*Overrides must match specificity of pre-loaded styles!*/
/*The simplest way to get the selector is with devtools*/
#fp-nav ul li,
.fp-slidesNav ul li {
    width: 15px !important;
    height: 30px !important;
    margin: 15px !important;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    #fp-nav {
        display: none;
    }
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
    width: 30px !important;
    height: 3px !important;
    background: #ffffff !important;
    opacity: 0.3 !important;
    border-radius: 25px !important;
    transform: rotate(90deg) !important;
}

#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
    background-color: #ffffff !important;
    opacity: 1 !important;
    width: 30px !important;
    height: 3px !important;
    border-radius: 25px !important;
    transform: rotate(90deg) !important;
}

#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
    margin: -3px 0 0 -2px !important;
}

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
    margin: -2px 0 0 -2px !important;
    opacity: .7 !important;
    width: 30px !important;
    height: 3px !important;
}
#fp-nav ul li .fp-tooltip.fp-left {
    left: 40px !important;
    top: 8px;
}
/* animation: into .3s cubic-bezier(0.4, 0, 0.6, 1) infinite;

@keyframes into: {
        0% { opacity: 0;transform: translateY(50px) }
        100%{ opacity: 100; transform: translateY(0px) }
} */
.mySwiper03 {
    @apply overflow-visible;
}
.mySwiper03 .swiper-wrapper {
    @apply items-stretch;
}
.mySwiper03 .swiper-wrapper .swiper-slide {
    @apply h-auto;
}
.mySwiper03 .swiper-scrollbar {
    @apply w-3/4 -inset-x-0 -bottom-10 h-4 box-border bg-white bg-opacity-10;
    left: calc(50% - 75% / 2);
}
.mySwiper03 .swiper-scrollbar-drag {
    @apply bg-white;
}
.mySwiper08 {
    @apply lg:w-auto pt-8 lg:pt-20 lg:px-80 lg:mx-10 box-border 3xl:px-0 3xl:mx-auto;

}
.mySwiper08 .swiper-wrapper{
    @apply 3xl:w-auto mx-auto;
    width: calc(320px * 5 + 40px * 4);
}
.mySwiper08 .swiper-wrapper:before{
    @apply absolute border-b border-dashed border-white left-0 opacity-20 top-32 h-1.5;
    content: "";
    display: block;
    width: calc(320px * 5 + 40px * 4);
}
.navigation-box .swiper-button-disabled svg{
    @apply cursor-auto opacity-10;
    pointer-events: none;
}
.m-screen06-grid1 {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.u-grid06-div div {
    /* background: rgba(133, 122, 153,0.1); */
    /* border: 1px solid rgba(255,255,255,0.1); */
    /* box-sizing: border-box;
    border-radius: 20px; */
}

.m-screen06-grid2 {
    height: 150px;
    align-items: center;
    justify-items: center;
    background: rgba(13, 12, 15, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 20px;
}

.screen06-warp {
    @apply w-screen pb-20 lg:pb-0 flex flex-col xl:justify-center px-5 lg:px-8 xl:w-1200 box-border md:h-auto lg:mx-auto;
}
.screen06-warp .page-title{
    @apply text-5xl text-left lg:hidden pb-11;
}
.screen06-warp-top {
    @apply flex-1 w-full pt-32 md:pt-20;
}
.screen06-warp-top-title {  
    @apply md:pt-16 md:mt-0.5 mb-4 font-sans text-3xl font-bold text-left;
}
.screen06-warp-top-logo {
    @apply w-full grid grid-cols-2 gap-7 md:grid-cols-3 xl:grid-cols-4;
}
.screen06-warp-top-logo-list {
    @apply w-full flex justify-center items-center border p-5 border-white h-36 border-opacity-10 rounded-3xl bg-gray-normal bg-opacity-10;
}
.screen06-warp-bottom-partners {
    @apply mt-8 mb-4 font-sans text-3xl font-bold text-left;
}
.screen06-warp-bottom-partners-list {
    @apply grid grid-cols-4 gap-4 px-2 py-4 md:grid-cols-8 border-opacity-10 rounded-xl bg-gray-normal bg-opacity-10;
}
.screen06anim {
    /* min-height: calc(100vh + 240px);f */
    @apply md:min-h-full items-center xl:w-1200 lg:mx-auto;
}
.token-chart {
    left: 0;
    right: 0;
    margin: auto;
    display: inline;
}
.sc-title {
    left: 0;
    right: 0;
    margin: auto;
}

.m-screen05-grid {
    display: grid;
    grid-template-columns: repeat(3, 0.1fr);
    grid-gap: 40px;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 80px;
}

.m-token-economy {
    width: 360px;
    height: 200px;
}
.u-token-economy-img {
    width: 80px;
    height: 80px;
}
.screen05-warp {
    @apply w-screen flex flex-col justify-between px-8 mx-auto lg:pt-20 xl:w-1200 box-border md:h-auto;
}
.screen05-warp-h1 {
    @apply pb-10 font-sans text-5xl font-bold text-left pt-24 md:text-center;
}
.screen05-warp-title-item {
    @apply flex-1 grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2 xl:grid-cols-3;
}
.screen05-warp-item-list {
    @apply w-full h-auto md:w-360 mb-12;
}
.screen05-warp-item-img {
    @apply w-20 h-20 mb-4;
}
.screen05-warp-item-h1 {
    @apply mb-2 text-2xl text-left;
}
.screen05-warp-item-div {
    @apply md:pr-4 text-base leading-relaxed text-left text-gray-light;
}
.screen05-warp-show-button {
    width: 230px;
    @apply flex items-center justify-center py-2 mx-auto mb-10 text-white bg-white rounded-full cursor-pointer bg-opacity-10 bottom-6;
}
.screen05-warp-show-button-img {
    @apply w-8 h-8 ml-2;
}
.screen05-economy-warp {
    @apply absolute inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-90;
}
.screen05-economy-layout {
    @apply relative z-20 w-5/6 xl:w-1200 px-20 py-10 rounded-2xl bg-gray-dialog;
}
.screen05-economy-button {
    @apply absolute z-10 text-white top-1 right-1 md:top-5 md:right-5;
}


.social-btn-wrap-footer {
    transition: max-width 0.2s;
    /* max-width: 64px; */
}

.social-btn-wrap-footer > i {
    color: #ffffff;
    height: 48px;
    width: 48px;
}

.social-btn-wrap-footer > .social-btn {
    background-color: #303030;
    border-radius: 50%;
    margin: 0 8px;
    padding: 8px;
    cursor: pointer;
    /* transition: background-color 200ms linear; */
}

.social-btn-wrap-footer > .social-btn:hover {
    background-color: #ffcc00;
}

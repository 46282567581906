.customModal-appdown {
    @apply shadow-none w-full md:w-96 h-auto ;
    max-width: 1200px !important;
    /* width: 100%;
    height:100%; */
    background: #151729 !important;
    padding: 2.2rem !important;
    overflow:hidden;
    margin: 0rem;
}

.react-responsive-modal-modal{
    max-width: 800px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    background: #ffffff;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
    margin: 1.2rem 1.2rem 0rem 0rem;
    padding: 1.2rem;
    position: relative;
    overflow-y: auto;
}
.downloadTitle{
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 1.5rem;
}

.customCloseButton-appdown {
    top: 30px !important;
    right: 20px !important;
    &:focus {
        border: 0;
        outline: 0;
    }
    &:active{
        border: 0;
        outline: 0;
    }
}

.customCloseButton svg {
    fill: #fff;
}

.screen02-warp {
    @apply w-screen h-auto lg:h-screen flex justify-start lg:justify-center items-start lg:items-center relative;
}
.screen02-layout {
    @apply pt-24 lg:pt-0 flex flex-col px-5 lg:px-0 lg:flex-row lg:items-center 2xl:h-600 xl:w-1200;
    /* min-height: calc(100vh + 200px) */
}
.screen02-layout-left {
    @apply flex-1 mt-12 cursor-pointer lg:flex items-center lg:mt-0 rounded-xl lg:order-first h-full;
}
.screen02-layout-left-warp {
    @apply h-auto relative lg:absolute lg:left-0 2xl:h-600 rounded-lg lg:rounded-r-lg lg:rounded-l-none;
}
.screen02-layout-left-img {
    @apply  w-full h-full rounded-2xl lg:rounded-2xl lg:rounded-l-none;
}
.screen02-layout-left-svg {
    @apply absolute w-full h-full inset-0 opacity-40 hover:opacity-100;
}
.screen02-svg {
    @apply absolute text-white top-1/2 left-1/2 -ml-5 -mt-5;
}
.screen02-layout-right {
    @apply flex items-center order-first text-left text-white lg:order-last  2xl:h-600 lg:w-1/2 lg:pl-10 2xl:pl-14;
}
.screen02-layout-right-warp {
    @apply flex flex-col md:space-y-4 lg:space-y-2 xl:space-y-8;
}
.screen02-layout-right-title {
    @apply text-5xl font-medium leading-14;
}
.screen02-layout-right-text1 {
    @apply mt-8 md:mt-10 text-lg leading-6 md:leading-8 tracking-wide lg:mt-0 lg:leading-relaxed opacity-70;
}
.screen02-layout-right-text2 {
    @apply mt-8 md:mt-10 text-lg leading-6 md:leading-8 lg:mt-0 lg:leading-relaxed opacity-70;
}

.screen08-warp {
    @apply px-5 lg:px-0 h-auto lg:h-screen w-screen flex box-border flex-col justify-between;
}
.screen08-warp-map {
    @apply flex-1 flex flex-col pt-32 justify-start lg:justify-center lg:-mt-32;
}
.screen08-warp-map-h1 {
    @apply text-left lg:text-center text-5xl font-bold;
}
.screen08-warp-map-subtitle {
    @apply text-left text-base leading-7 opacity-80 pt-7 lg:text-center whitespace-pre-line;
}
.screen08-warp-bottom {
    @apply hidden lg:block mx-auto w-full lg:w-1200 border-t border-white border-opacity-10 py-7;
}
.screen08-warp-bottom-text {
    @apply flex justify-between opacity-75;
    
}
.screen08-warp-footer {
    @apply lg:hidden w-full m-auto bottom-10;
}
